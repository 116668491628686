import { NgComponentOutlet } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import type { PushNotificationsTwaComponent } from '@bcf-libs/ui/others/components/sportsbook/lvbetpl/mobile-web/src/lib/push-notifications/push-notifications-twa.component';
import { AppLoadedService } from '@bcf-v2-ui-shared/services/app-loaded.service';
import type { AppMaintenanceService } from '@bcf-v2-ui-shared/services/app-maintenance.service';
import { maintenanceRoutePath } from '@bcf-v2/routes/common/app/paths/maintenance-route-path';
@Component({
  standalone: true,
  selector: 'bcf-root',
  imports: [RouterOutlet, NgComponentOutlet],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public pushNotificationsTwaComponent: typeof PushNotificationsTwaComponent | undefined;

  constructor(
    private _router: Router,
    private _injector: Injector,
    private _appLoadedService: AppLoadedService
  ) {}

  public ngOnInit(): void {
    this._initApp();
    this._initPushNotifications();
    this._initAppMaintenance();
    this._appLoadedService.init();
  }

  private async _initApp(): Promise<void> {
    this._injector
      .get(
        (
          await import(
            /* webpackChunkName: "app-initializator" */ '@apps/projects/sportsbook/lvbetpl/mobile-web/src/app/app-initializator'
          )
        ).LazyInitializatorSbLvbetplMobileWeb
      )
      .init();
  }

  private async _initPushNotifications(): Promise<void> {
    this.pushNotificationsTwaComponent = await import(
      /* webpackChunkName: "push-notifications-initializer" */ '@bcf-libs/ui/others/components/sportsbook/lvbetpl/mobile-web/src/lib/push-notifications/push-notifications-twa.component'
      // eslint-disable-next-line @typescript-eslint/typedef
    ).then((mod) => mod.PushNotificationsTwaComponent);
  }

  private async _initAppMaintenance(): Promise<void> {
    const instance: AppMaintenanceService = this._injector.get(
      (await import(/* webpackChunkName: "app-maintenance" */ '@bcf-v2-ui-shared/services/app-maintenance.service'))
        .AppMaintenanceService
    );
    instance.doRedirectToMaintenance().subscribe(() => this._router.navigate([maintenanceRoutePath()]));
  }
}
